@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import 'variables';
@import 'colors';
@import 'forms';
@import 'forms_dark';
@import 'buttons';
@import 'helpers';
@import 'typography';
@import 'grid';
@import 'accessibility';
@import 'crossbrowser-fixes/crossbrowser-fixes.scss';

html {
  overflow: hidden;
  height: 100vh;
  background-color: $secondary-color;
}

body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: map-get($di-grey-map, 50);
  font-family: var(--font-default);
  color: $text-dark;

  &.gradient-background {
    background: linear-gradient(95deg, #033683, #0a3e8c 19%, #5893f6) no-repeat
      fixed;
  }
}

.screen-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.screen-full-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  pointer-events: auto !important;
  background-color: $extra-light;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.row.row-no-margin {
  margin: 0 !important;
}

.toast {
  &.error {
    background-color: $dark-error-color;
    color: $white;
  }

  &.warn {
    background-color: $error-color;
    color: $white;
  }

  &.info {
    background-color: $success-color;
    color: $white;
  }
}

.dropdown-content {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.fullscreen-y-scroll {
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

div.section.body {
  top: $header-height;
  @media (max-width: 992px) {
    top: $header-height-mobile;
  }
  padding-top: 16px;
  padding-bottom: 300px;
  position: fixed;
  width: 100%;
  height: calc(100vh - #{$header-height});
  @media (max-width: 992px) {
    height: calc(100vh - #{$header-height-mobile});
  }
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  .row {
    margin-bottom: 10px;
  }
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.cdk-overlay-custom-dialog {
  .mat-dialog-container {
    padding: 0;
    border-radius: 6px;
  }
}
