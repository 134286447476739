.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 10px;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 10px;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
