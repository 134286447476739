@import 'colors';
@import 'variables';

button:not(.mat-button-base) {
  @extend .white;
  font-weight: bold !important;
  color: $text-dark !important;
  border-radius: $button-radius !important;
}

a.btn {
  @extend .white;
  font-weight: bold !important;
  color: $text-dark !important;
  border-radius: $button-radius !important;

  &.btn-floating {
    border-radius: 50% !important;
  }
}

.fixed-action-btn > a > i {
  color: $text-dark !important;
}

button:not(.mat-button-base),
a.btn {
  @extend .disable-select;

  &.btn-circle {
    border-radius: 50% !important;
    width: 36px;
    height: 36px;
    align-content: center;
    text-align: center;
    vertical-align: middle;
    padding: 0;
  }
}

.btn-large {
  font-size: 1.286rem;
}

.btn-x-large {
  font-size: 1.714em;
  height: auto;
  padding: 20px 50px;
}

.btm-tap-initials {
  position: absolute;
  right: 20px;
}

/*
 When creating your CSS selector,
 change "brown" to something of your choosing
 */
.waves-effect.waves-primary .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: $ripple-color;
}

button {
  &.modento-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 500 !important;
    font-size: 16px;
    padding: 0 24px;
    transition: all 0.25s;
  }

  &.primary-variant {
    height: 44px;
    line-height: 44px;
    border-radius: 10px !important;
    color: white !important;
    background: map-get($di-primary-map, 500) !important;

    &:hover {
      background-color: map-get($primary-palette, 400) !important;
      color: white !important;
    }

    &:focus {
      background-color: map-get($primary-palette, 900) !important;
      color: white !important;
    }
  }

  &.secondary {
    background-color: map-get($primary-palette, 50) !important;
    color: map-get($secondary-palette, 500) !important;
    height: 32px;
    line-height: 32px;
    border-radius: 10px !important;
    i {
      color: map-get($di-primary-map, 500) !important;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px map-get($di-primary-map, 500) !important;
      color: map-get($secondary-palette, 500) !important;
      i {
        color: map-get($di-primary-map, 500) !important;
      }
    }

    &:focus {
      background-color: map-get($primary-palette, 900) !important;
      color: white !important;
      i {
        color: white !important;
      }
    }
  }

  &.text {
    color: map-get($di-primary-map, 500) !important;
    height: 32px;
    line-height: 32px;

    &:hover {
      color: map-get($primary-palette, 400) !important;
    }

    &:focus {
      color: map-get($primary-palette, 900) !important;
      background: white;
    }
  }

  .suffix {
    margin-left: 8px;
  }

  .prefix {
    margin-right: 8px;
  }
}
