@import 'colors';

:root {
  --font-default: 'Red Hat Text', sans-serif;
}

.helper-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 0.857em;

  &.invalid {
    color: $error-color !important;
  }

  &.primary {
    color: map-get($secondary-palette, 500) !important;
  }

  &.light {
    color: map-get($secondary-palette, 300) !important;
  }
}

table {
  color: $white;
}

app-edit-question,
app-form {
  table {
    color: $primary-color;
  }
}

span.required {
  font-size: x-small;
  font-weight: bold;
  vertical-align: super;
}

.text-light {
  color: $light !important;
}

.text-extra-light {
  color: $extra-light !important;
}

.text-error {
  color: $error-color !important;
}

.font-increased {
  font-size: 1.3rem;
}

/** Block text styling (for consents etc.) **/

p.block-text {
  color: map-get($secondary-palette, 500);
  font-size: 0.964rem;
  text-align: justify;
  margin-bottom: 0;
  -ms-word-break: break-word;
  word-break: break-word;
}

p.block-text.no-html {
  white-space: pre-wrap;
}

p.block-text {
  * {
    color: map-get($secondary-palette, 500) !important;
    font-size: 0.964rem;
  }

  strong {
    font-weight: bold;
  }
}

textarea {
  font-family: var(--font-default);
}
