/* You can add global styles to this file, and also import other style files */
@import '~materialize-css/sass/components/_color-variables.scss';
@import 'scss/colors';
@import '~materialize-css/sass/materialize.scss';

@import 'scss/style';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

strong {
  font-weight: 600;
}
