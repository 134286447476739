@import 'colors';
@import 'variables';

.dark-forms {
  form .col > p,
  form .col .col > p,
  form .col > span {
    color: $text-dark !important;
    margin-top: 0 !important;
  }

  // input fields styling
  .input-field,
  .input-field.file-field,
  .input-field > div.select-wrapper {
    @extend .white-text;

    /* label color */
    label {
      color: $text-dark !important;
    }

    label:not(.label-icon).active {
      transform: translateY(-14px) scale(0.9) !important;
    }

    /* label underline focus color */
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='password'],
    input[type='search'] {
      color: $text-dark !important;
      border-bottom: 1px solid $text-grey !important;
      box-shadow: 0 1px 0 0 $text-grey !important;
    }

    /* label focus color */
    input[type='text']:focus + label,
    input[type='email']:focus + label,
    input[type='number']:focus + label,
    input[type='tel']:focus + label,
    input[type='password']:focus + label,
    input[type='search']:focus + label {
      color: $text-dark !important;
      font-weight: bold !important;
    }

    /* label underline focus color */
    input[type='text']:focus,
    input[type='email']:focus,
    input[type='number']:focus,
    input[type='tel']:focus,
    input[type='password']:focus,
    input[type='search']:focus {
      border-bottom: 1px solid $text-dark !important;
      box-shadow: 0 1px 0 0 $text-dark !important;
    }

    /* valid color */
    input[type='text'].valid,
    input[type='email'].valid,
    input[type='number'].valid,
    input[type='tel'].valid,
    input[type='password'].valid,
    input[type='search'].valid {
      border-bottom: 1px solid $text-dark !important;
      box-shadow: 0 1px 0 0 $text-dark !important;
    }

    /* invalid color */
    input[type='text'].invalid,
    input[type='email'].invalid,
    input[type='number'].invalid,
    input[type='tel'].invalid,
    input[type='password'].invalid,
    input[type='search'].invalid {
      border-bottom: 1px solid $error-color !important;
      box-shadow: 0 1px 0 0 $error-color !important;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    ::placeholder {
      color: $text-grey !important;
    }

    div.btn {
      @extend .white;
      border-radius: $button-radius !important;
      color: $text-grey !important;
      font-weight: bold;
    }

    .select-wrapper {
      .caret {
        fill: $text-dark;
      }

      & + label {
        font-size: 0.9rem;
      }
    }

    .helper-text.invalid {
      color: $error-color !important;
    }
  }

  // radio styling
  input[type='radio'] {
    &:checked + span:after,
    &.with-gap:checked + span:before,
    &.with-gap:checked + span:after {
      border: 2px solid $text-dark !important;
    }

    & + span:after,
    &.with-gap + span:before,
    &.with-gap + span:after {
      border: 1px solid $text-dark !important;
    }

    &:checked + span:after,
    &.with-gap:checked + span:after {
      background-color: $text-dark !important;
    }

    & + span {
      color: $text-dark !important;
    }
  }

  // checkbox styling
  input[type='checkbox'].filled-in {
    &:checked + span:not(.lever):after {
      border: 2px solid $text-dark !important;
      background-color: $text-dark !important;
    }

    &:checked + span:not(.lever):before {
      border-right-color: $secondary-color !important;
      border-bottom-color: $secondary-color !important;
    }

    &:not(:checked) + span:not(.lever):after {
      border-color: $text-dark !important;
    }
  }

  label.for-checkbox {
    color: $text-dark !important;
  }

  .switch label {
    color: $text-dark !important;

    input[type='checkbox']:checked + .lever:after {
      background-color: $text-dark;
    }
  }

  .select-dropdown {
    li.disabled span {
      color: $text-grey !important;
    }

    li.selected:not(.disabled) span {
      color: $secondary-color !important;
    }

    li span {
      color: $text-dark !important;
    }
  }

  p.fixed-label {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: $text-dark;

    &.focused {
      font-weight: bold;
    }
  }
}

app-forms-request {
  form .col > p,
  form .col .col > p,
  form .col > span {
    color: map-get($secondary-palette, 500) !important;
    margin-top: 0 !important;
  }

  // input fields styling
  .input-field,
  .input-field.file-field,
  .input-field > div.select-wrapper {
    @extend .white-text;

    /* label color */
    label {
      color: map-get($secondary-palette, 500) !important;
    }

    label:not(.label-icon).active {
      transform: translateY(-14px) scale(0.9) !important;
    }

    /* label underline focus color */
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='password'],
    input[type='search'] {
      color: map-get($secondary-palette, 500) !important;
      border-bottom: 1px solid map-get($secondary-palette, 300) !important;
      box-shadow: none !important;
    }

    /* label focus color */
    input[type='text']:focus + label,
    input[type='email']:focus + label,
    input[type='number']:focus + label,
    input[type='tel']:focus + label,
    input[type='password']:focus + label,
    input[type='search']:focus + label {
      color: map-get($secondary-palette, 500) !important;
      font-weight: bold !important;
    }

    /* label underline focus color */
    input[type='text']:focus,
    input[type='email']:focus,
    input[type='number']:focus,
    input[type='tel']:focus,
    input[type='password']:focus,
    input[type='search']:focus {
      border-bottom: 1px solid map-get($secondary-palette, 500) !important;
      box-shadow: none !important;
    }

    /* valid color */
    input[type='text'].valid,
    input[type='email'].valid,
    input[type='number'].valid,
    input[type='tel'].valid,
    input[type='password'].valid,
    input[type='search'].valid {
      border-bottom: 1px solid map-get($secondary-palette, 500) !important;
      box-shadow: none !important;
    }

    /* invalid color */
    input[type='text'].invalid,
    input[type='email'].invalid,
    input[type='number'].invalid,
    input[type='tel'].invalid,
    input[type='password'].invalid,
    input[type='search'].invalid {
      border-bottom: 1px solid map-get($warn-palette, 500) !important;
      box-shadow: none !important;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    ::placeholder {
      color: map-get($secondary-palette, 200) !important;
    }

    div.btn {
      @extend .white;
      border-radius: $button-radius !important;
      color: map-get($secondary-palette, 500) !important;
      font-weight: bold;
    }

    .select-wrapper {
      .caret {
        fill: map-get($secondary-palette, 500);
      }

      & + label {
        font-size: 0.9rem;
      }
    }

    .helper-text.invalid {
      color: map-get($warn-palette, 500) !important;
    }
  }

  .input-field:not(.selected)
    .select-wrapper
    input[type='text'].select-dropdown {
    color: map-get($secondary-palette, 200) !important;
  }

  // radio styling
  input[type='radio'] {
    &:checked + span:after,
    &.with-gap:checked + span:before,
    &.with-gap:checked + span:after {
      border: 1px solid map-get($di-primary-map, 500) !important;
    }

    & + span:after,
    &.with-gap + span:before,
    &.with-gap + span:after {
      border: 1px solid map-get($di-primary-map, 500) !important;
    }

    &:checked + span:after,
    &.with-gap:checked + span:after {
      background-color: map-get($di-primary-map, 500) !important;
    }

    & + span {
      color: map-get($secondary-palette, 500) !important;
    }
  }

  // checkbox styling
  input[type='checkbox'].filled-in {
    & + span:not(.lever) {
      line-height: 23px !important;
    }

    &:checked + span:not(.lever):after {
      border: 1px solid map-get($di-primary-map, 500) !important;
      border-radius: 4px;
      background-color: transparent !important;
    }

    &:checked + span:not(.lever):before {
      border-right-color: map-get($di-primary-map, 500) !important;
      border-bottom-color: map-get($di-primary-map, 500) !important;
    }

    &:not(:checked) + span:not(.lever):after {
      border-radius: 4px;
      border: 1px solid map-get($di-primary-map, 500) !important;
    }
  }

  label.for-checkbox {
    color: map-get($secondary-palette, 500) !important;
  }

  .switch label {
    color: map-get($secondary-palette, 500) !important;

    input[type='checkbox']:checked + .lever:after {
      background-color: map-get($secondary-palette, 500);
    }
  }

  .select-dropdown {
    li.disabled span {
      color: map-get($custom-map, modento-disabled-font) !important;
    }

    li.selected:not(.disabled) span {
      color: map-get($di-primary-map, 500) !important;
    }

    li span {
      color: map-get($secondary-palette, 500) !important;
    }
  }

  p.fixed-label {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: map-get($secondary-palette, 500);

    &.focused {
      font-weight: bold;
    }
  }
}
