// Primary colors
// -------------------------
$primary-color: #435275 !default;
$secondary-color: #4c7be5 !default;
$ripple-color: #4c7be5aa !default;
$success-color: #57ab00 !default;
$error-color: #ff6e19 !default;
$dark-error-color: #700063 !default;
$link-color: #fff !default;
$grey-color: #cccccc !default;
$warning-color: #ffc111 !default;

$white: #fff;
$mildly-light: rgba(255, 255, 255, 0.8);
$light: rgba(255, 255, 255, 0.5);
$extra-light: rgba(255, 255, 255, 0.2);
$text-dark: #435275;
$text-grey: #9e9e9e;

$primary-palette: (
  50: #e1efff,
  100: #bfd5f1,
  200: #95bae8,
  300: #6a9ede,
  400: #4a89d7,
  500: #2a74d0,
  600: #256ccb,
  700: #1f61c4,
  800: #1957be,
  900: #0f44b3,
  A100: #e1eaff,
  A200: #aec5ff,
  A400: #7ba0ff,
  A700: #628eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$secondary-palette: (
  50: #f1f3f4,
  100: #bbc2c7,
  150: #838789,
  200: #697781,
  300: #3f515f,
  400: #273c4d,
  500: #1d3243,
  600: #1a2d3d,
  700: #152634,
  800: #111f2c,
  900: #0a131e,
  A100: #5da3ff,
  A200: #2a86ff,
  A400: #006af6,
  A700: #005fdd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$warn-palette: (
  50: #fef7f4,
  100: #fbcebf,
  200: #f8ad94,
  300: #f58c69,
  400: #f37349,
  500: #f15a29,
  600: #ef5224,
  700: #ed481f,
  800: #eb3f19,
  900: #dc3701,
  A100: #27a860,
  A200: #c7fade,
  A400: #ffb6ad,
  A700: #ff9f93,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$custom-map: (
  navy-bluish: #435174,
  modento-green: #29b473,
  modento-blue: #26aad4,
  modento-disabled-font: #697781
);

$di-primary-map: (
  50: #e6eff9,
  100: #b0ceed,
  200: #8ab6e5,
  300: #5494d9,
  400: #3380d1,
  500: #0060c6,
  600: #0057b4,
  700: #00448d,
  800: #00356d,
  900: #002853
);

$di-grey-map: (
  50: #f8f8f9,
  100: #f2f3f5,
  200: #bbc2c7,
  300: #6b7886,
  400: #415467,
  500: #324354,
  600: #1d3243,
  700: #152634,
  800: #111f2c,
  900: #0a131e,
  C100: #505050
);

$di-success-map: (
  50: #e1f5ed,
  100: #b5e5d2,
  200: #84d4b4,
  300: #53c296,
  400: #2eb580,
  500: #09a869,
  600: #068755,
  700: #06774b,
  800: #055c3a,
  900: #04472c,
);
