body.accessibility-mobile-font {
  .input-field > label,
  input,
  p:not(.header-text) {
    font-size: 1.25rem !important;
  }
  .compact p {
    font-size: 1.15rem !important;
  }
  p.block-text {
    font-size: 1.15rem !important;
  }
  input + span {
    font-size: 1.15rem !important;
  }
  .helper-text {
    font-size: 0.95rem !important;
  }
  label:not(.label-icon).active {
    transform: translateY(-18px) scale(0.9) !important;
  }
  .select-wrapper + label {
    top: -30px;
  }
  .compact {
    div.input-field {
      margin-top: 18px !important;
    }
  }
}

body.accessibility-big-font {
  .input-field > label,
  input,
  p:not(.header-text) {
    font-size: 1.7rem !important;
  }
  .compact p {
    font-size: 1.5rem !important;
  }
  p.block-text {
    font-size: 1.5rem !important;
  }
  input + span {
    font-size: 1.5rem !important;
  }
  .helper-text {
    font-size: 1.3rem !important;
  }
  label:not(.label-icon).active {
    transform: translateY(-25px) scale(0.9) !important;
  }
  .select-wrapper + label {
    top: -44px;
  }
  div.section.body .row {
    margin-bottom: 20px !important;
  }
  .compact {
    div.input-field {
      margin-top: 24px !important;
    }
  }
  [type='radio']:not(:checked) + span,
  [type='radio']:checked + span {
    padding-left: 30px !important;
  }
  a.btn-flat {
    font-size: 1.2rem !important;
  }

  .ql-editor {
    h1 {
      font-size: 4em;
    }
    h2 {
      font-size: 3em;
    }
    h3 {
      font-size: 2.34em;
    }
    h4 {
      font-size: 2em;
    }
    h5 {
      font-size: 1.68em;
    }
    h6 {
      font-size: 1.34em;
    }
  }
}
