.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 98%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
    max-width: 85% !important;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
    max-width: 70% !important;
  }
}
